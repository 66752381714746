
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('grantDistribution.applicant_list') }}</h4>
            </template>
             <template v-slot:body>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
                  <b-row>
                    <b-col xs="12" sm="12" md="6">
                      <ValidationProvider name="Applicant_id" vid="applicant_id">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="applicant_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('grantDistribution.applicant_id')}}
                          </template>
                          <b-form-input
                              id="applicant_id"
                              v-model="search.application_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6">
                      <ValidationProvider name="From Date" vid="from_date">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="from_date"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('grantDistribution.applicant_submit_date')}} {{$t('grantDistribution.from')}}
                          </template>
                          <b-form-input
                              id="datepicker"
                              v-model="search.from_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6">
                      <ValidationProvider name="To Date" vid="to_date">
                        <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="to_date"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('grantDistribution.applicant_submit_date')}} {{$t('grantDistribution.to')}}
                          </template>
                          <b-form-input
                              id="datepicker"
                              v-model="search.to_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6">
                      <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('grantDistribution.applicant_list') }}</h4>
              </template>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row>
                    <b-col md="12" class="table-responsive">
                      <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                        <template v-slot:cell(index)="data">
                          {{ $n(data.index + pagination.slOffset) }}
                        </template>
                        <template v-slot:cell(application_id)="data">
                          {{ $n(data.item.application_id, { useGrouping: false }) }}
                        </template>
                        <template v-slot:cell(created_at)="data">
                          {{ data.item.created_at | dateFormat  }}
                        </template>
                        <template v-slot:cell(status)="data">
                          <!-- <b-button v-if="data.item.deputation_id === null" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                          <!-- <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                          <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                              <i class="fas fa-eye"></i>
                          </a>
                          <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-if="data.item.deputation_id === null" v-b-modal.modal-4 @click="edit(data.item)">
                              <i class="ri-ball-pen-fill"></i>
                          </a>
                        </template>
                      </b-table>
                      <b-pagination
                        align="center"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                      />
                    </b-col>
                  </b-row>
                </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('grantDistribution.uni_depu')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="editItemId" :key="editItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { universityDeputationList, appCommitteeStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        application_id: '',
        from_date: '',
        to_date: ''
      },
      rows: [],
      item: '',
      orgList: []
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('grantDistribution.approval_committee_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('grantDistribution.fund_distribution') + ' ' + this.$t('globalTrans.modify')
    },
    committeeList: function () {
      return this.$store.state.incentiveGrant.commonObj.committeeList.filter(item => item.status === 1)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.application_id'), class: 'text-center' },
          { label: this.$t('grantDistribution.applicant_name'), class: 'text-center' },
          { label: this.$t('grantDistribution.applicant_submit_date'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'created_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'created_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.can_org_type': function (newVal, oldVal) {
      this.orgList = this.getOrganization(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    getOrganization () {
      if (this.search.can_org_type === 2) {
        const circularList = this.$store.state.ExternalUserIrrigation.incentiveGrant.narseInstituteList.filter(item => item.status === 1)
        this.orgList = circularList.map(item => {
          return Object.assign({}, item, { value: item.value, text: item.text_en })
        })
      } else {
        const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        this.orgList = orgList.map(item => {
          return Object.assign({}, item, { value: item.value, text: item.text_en })
        })
      }
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, appCommitteeStatus, item, 'incentive_grant', 'enlistedUniversityList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, universityDeputationList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
