<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Applicant Id" vid="applicant_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="applicant_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('grantDistribution.applicant_id') }}
                                    </template>
                                    <b-form-input
                                    readonly
                                    id="applicant_id"
                                    plain
                                    v-model="form.application_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Applicant Name" vid="name">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="name"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.applicant_name')}}
                          </template>
                            <b-form-input
                                readonly
                                id="name"
                                v-model="form.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Present Position" vid="can_org_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.present_position')}}
                          </template>
                            <b-form-input
                                readonly
                                id="can_org_id"
                                v-model="form.designation"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="univerisity name" vid="university_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.university_name')}}
                          </template>
                          <b-form-select
                                plain
                                v-model="form.university_id"
                                :options="universityList"
                                id="university_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >
                                    {{$t('globalTrans.select')}}
                                  </b-form-select-option>
                                </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="course name" vid="">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.course_name_en')}}
                          </template>
                            <b-form-input
                                id="can_org_id"
                                v-model="form.field_of_study"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="course name" vid="">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.course_name_bn')}}
                          </template>
                            <b-form-input
                                id="can_org_id"
                                v-model="form.field_of_study_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="course name" vid="">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.admission_date')}}
                          </template>
                            <b-form-input
                                id="payment_date"
                                v-model="form.admission_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                        <template>
                          <div style="font-size:12px; background-color: #66CC99; padding:6px">
                              <h5 class="text-white text-center"> {{ $t('grantDistribution.deputation_duration') }}</h5>
                          </div>
                        </template>
                      <b-row>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Deputation Start Date" vid="deputation_start_date">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.deputation_start_date')}}
                          </template>
                            <b-form-input
                                id="payment_date"
                                v-model="form.deputation_start_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Deputation End Date" vid="deputation_end_date">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.deputation_end_date')}}
                          </template>
                            <b-form-input
                                id="payment_date"
                                v-model="form.deputation_end_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Total Month" vid="total_month">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="total_month"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.tot_month')}}
                          </template>
                            <b-form-input
                                id="total_month"
                                v-model="form.total_month"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { universityDeputationUpdate } from '../../api/routes'
import moment from 'moment'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFundDistributionData()
      this.form = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#payment_date', {})
  },
  data () {
    return {
      loading: false,
      addmissionshow: false,
      stipendshow: false,
      researchGrantShow: false,
      othersshow: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      form: {
        deputation_start_date: '',
        deputation_end_date: '',
        total_month: 0
      }
    }
  },
  watch: {
    'form.deputation_end_date': function (newVal, oldVal) {
      // if (newVal !== oldVal) {
        this.form.total_month = this.getTotalMonth()
      // }
    }
  },
  computed: {
    universityList: function () {
     const headlist = this.$store.state.incentiveGrant.commonObj.enlistedUniversityList.filter(item => item.status === 1)
      return headlist.map(item => {
       if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  methods: {
    getFundDistributionData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getTotalMonth () {
      return parseInt(moment(new Date(this.form.deputation_end_date)).diff(new Date(this.form.deputation_start_date), 'months', true))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const formData = Object.assign(this.form, { app_general_info_id: this.form.id })
      result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${universityDeputationUpdate}`, formData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
