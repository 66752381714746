<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <b-overlay :show="loading">
              <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <div>
                      <b-table-simple striped bordered small>
                        <b-tr>
                          <b-th>{{ $t('grantDistribution.applicant_id') }}</b-th>
                          <b-td>{{ ssPolictSetupData.application_id }}</b-td>
                          <b-th>{{ $t('grantDistribution.applicant_name') }}</b-th>
                          <b-td>{{ (this.$i18n.locale=='bn')? ssPolictSetupData.name_bn : ssPolictSetupData.name }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>{{ $t('grantDistribution.present_position') }}</b-th>
                          <b-td>{{ (this.$i18n.locale=='bn')? ssPolictSetupData.designation_bn : ssPolictSetupData.designation }}</b-td>
                          <!-- <b-th>{{ $t('globalTrans.organization') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? getOrganization.canOrg_name_bn : getOrganization.canOrg_name }}</b-td> -->
                        </b-tr>
                      </b-table-simple>
                      <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                                <h5 class='complain-title'>{{ $t('grantDistribution.uni_course_assign') }}</h5>
                            </b-col>
                      </b-row>
                      <b-table-simple striped bordered small>
                        <b-tr>
                          <b-th>{{ $t('grantDistribution.university_name') }}</b-th>
                          <b-td>{{ (this.$i18n.locale=='bn')? ssPolictSetupData.unversity_name_bn : ssPolictSetupData.unversity_name }}</b-td>
                          <b-th>{{ $t('grantDistribution.uni_add') }}</b-th>
                          <b-td>{{ (this.$i18n.locale=='bn')? ssPolictSetupData.address_bn : ssPolictSetupData.address }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>{{ $t('grantDistribution.course_name_en') }}</b-th>
                          <b-td>{{ ssPolictSetupData.field_of_study }}</b-td>
                          <b-th>{{ $t('grantDistribution.course_name_bn') }}</b-th>
                          <b-td>{{ ssPolictSetupData.field_of_study_bn  }}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>{{ $t('grantDistribution.admission_date') }}</b-th>
                          <b-td>{{ ssPolictSetupData.admission_date|dateFormat }}</b-td>
                          <b-th></b-th>
                          <b-td></b-td>
                        </b-tr>
                      </b-table-simple>
                        <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                                <h5 class='complain-title'>{{ $t('grantDistribution.deputation_duration') }}</h5>
                            </b-col>
                      </b-row>
                      <b-table-simple striped bordered small>
                        <b-tr>
                          <b-th>{{ $t('grantDistribution.deputation_start_date') }}</b-th>
                          <b-td>{{ ssPolictSetupData.deputation_start_date|dateFormat }}</b-td>
                          <b-th>{{ $t('grantDistribution.deputation_end_date') }}</b-th>
                          <b-td>{{ ssPolictSetupData.deputation_end_date|dateFormat }}</b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </b-overlay>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>

import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { universityDeputationShowData } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id'],
    created () {
      if (this.id) {
        this.getFundDistributionData()
      }
    },
    data () {
      return {
        ssPolictSetupData: [],
        slOffset: 1
      }
    },
    computed: {
    },
    methods: {
      async getFundDistributionData () {
        this.loading = true
        const response = await RestApi.getData(incentiveGrantServiceBaseUrl, `${universityDeputationShowData}/${this.id}`)
        this.loading = false
        if (response.success) {
          this.ssPolictSetupData = this.relationData(response.data)
        } else {
          this.ssPolictSetupData = []
        }
      },
      relationData (data) {
        const uniData = this.$store.state.incentiveGrant.commonObj.enlistedUniversityList.filter(item => item.status === 1 && item.value === data.university_id)
        const dataObject = {}
        if (typeof dataObject !== 'undefined') {
          dataObject.unversity_name = uniData[0].text_en
          dataObject.unversity_name_bn = uniData[0].text_bn
          dataObject.address = uniData[0].address
          dataObject.address_bn = uniData[0].address_bn
        } else {
          dataObject.unversity_name = ''
          dataObject.unversity_name_bn = ''
          dataObject.address = ''
          dataObject.address_bn = ''
        }
        return Object.assign({}, data, dataObject)
      }
    }
  }
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
